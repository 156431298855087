.svg path.fill {
  fill: var(--color-secondary);
}
.svg path.spline-path {
  stroke: var(--color-secondary);
  cursor: pointer;
}
.svg {
  width: 490px;
}
.circle:hover {
  cursor: move;
}

.svg line {
  stroke-width: 1;
  stroke: #999;
  stroke-linecap: round;
  stroke-dasharray: 5, 3;
}
.svg .circle {
  stroke: none;
  fill: none;
}
.svg:hover .circle {
  stroke: black;
  fill: grey;
}
