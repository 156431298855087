@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}
.bg-primary {
  background-color: #003b7a;
}
.color-etc-blue {
  color: #003b7a;
}
.rounded-overview {
  border-radius: 1.3rem;
}

.schedule-bg {
  background: #e0eafc; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--color-color6),
    var(--color-color7)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    var(--color-color6),
    var(--color-color7)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.max-h-200 {
  max-height: 200px;
}
.max-h-400 {
  max-height: 400px;
}
.max-h-300 {
  max-height: 300px;
}
.bg-white-30-percent {
  background-color: #ffffff30;
}
.hover\:bg-white-30-percent:hover {
  background-color: #ffffff30;
}
.w-800px {
  width: 900px;
}
.w-500px {
  width: 500px;
}
.w-330px {
  width: 330px;
  min-width: 330px;
}
body {
  color: #202020;
}
.schedule-bg {
  color: black;
}
.text-shadow {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.text-shadow-md {
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
}

.text-shadow-lg {
  text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
}

.text-shadow-none {
  text-shadow: none;
}
.z--10 {
  z-index: -10;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7GxKOzY.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto Black"), local("Roboto-Black"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfChc4EsA.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.dropdownlink.active {
  background-color: var(--color-color3);
}
.scroll-snap-x-item {
  position: relative;
  scroll-snap-align: center;
}

@media (max-width: 1000px) {
  .scroll-snap-x-full {
    min-width: 100vw;
  }
  .scroll-snap-x-item:nth-child(2) {
    left: -200px;
  }
  .scroll-snap-x-item:nth-child(3) {
    left: -400px;
  }
  .scroll-snap-x {
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
  }
}

@media (max-width: 767px) {
  .scroll-snap-x-item:nth-child(2) {
    left: -60px;
  }
  .scroll-snap-x-item:nth-child(3) {
    left: -120px;
  }
}
@media (min-width: 1000px) {
  .scroll-snap-x-item {
    min-width: 340px !important;
  }
}
@media (min-width: 1100px) {
  .scroll-snap-x {
    margin: auto;
    max-width: 1100px;
  }

  .scroll-snap-x-item {
    min-width: 400px !important;
  }
}
.scroll-snap-x {
  padding-top: 12px;
  white-space: initial !important;
}

@media (min-width: 1400px) {
  .scroll-snap-x {
    max-width: 1400px;
    margin: auto;
  }
  .scroll-snap-x-item {
    min-width: 470px !important;
  }
}

.ehc-day-shadow {
  box-shadow: 2px 2px 15px #0000008b;
}
.supminute {
  top: -0.5em !important;
}

/* iPhone 5 settings */
@media (max-width: 350px) {
  .text-5xl {
    font-size: 2.5em;
  }
  .text-4xl {
    font-size: 2em;
  }
  .text-xl {
    font-size: 1em;
  }
  sup.text-xl {
    font-size: 0.6em;
  }
}
.text-6xl {
  line-height: 1em;
}
.text-5xl {
  line-height: 1em;
}
.inherit {
  position: inherit;
}
.float-label-wrapper {
  position: relative;
}

.float-label-wrapper label {
  position: absolute;
  top: 1em;
  left: 1em;
  opacity: 0;
}

.float-label-wrapper.focused label,
.float-label-wrapper.populated label {
  opacity: 1;
}

.float-label-wrapper.focused input,
.float-label-wrapper.populated input,
.float-label-wrapper.focused textarea,
.float-label-wrapper.populated textarea {
  padding-top: 2em;
}

.float-label-wrapper input,
.float-label-wrapper textarea,
.float-label-wrapper label {
  transition: 0.2s ease-in-out;
}
.ehc-login-logo {
  top: -80px;
  right: 25px;
}
/*
input::placeholder {
  color: white;
}
*/
input:focus {
  outline: 0;
}
.clip-circle {
  clip-path: circle(50% at center);
}
.ehc-blend {
  background: var(--color-primary) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  filter: grayscale(100%);
  opacity: 0.7;
}
.ehc-mt-95 {
  margin-top: 70%;
}
.w-44 {
  width: 11rem;
}
.h-44 {
  height: 11rem;
}
.ehc-user-profile {
  position: absolute;
  right: 1.5rem;
  top: -4rem;
}
.top--2 {
  top: -2rem;
}
.top--1 {
  top: -1rem;
}
.w-120px {
  width: 120px;
}
.h-120px {
  height: 120px;
}
.underline {
  /*text-decoration: none;
  border-bottom: 1px solid;
  */
  text-underline-position: under;
}
.bg-black-opacity-25 {
  background-color: #000000c0;
  backdrop-filter: blur(3px);
}
:focus {
  outline: none;
}
.tinymceview hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.tinymceview ul,
.tinymceview ol {
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.tinymceview ul {
  list-style: disc;
}
.tinymceview ol {
  list-style: decimal;
}

.admin a:hover {
  text-decoration: underline;
}
.help-block {
  font-size: 0.9em;
  padding: 2px;
  color: #505050;
  position: relative;
  top: 2px;
}
.menu-max-height {
  max-height: 89vh;
  overflow-y: auto;
}
.gap-x-2 {
  row-gap: 1rem;
}

body {
  overflow-y: scroll;
}
body::-webkit-scrollbar {
  width: 14px; /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}
body::-webkit-scrollbar-thumb {
  background-color: #a7a5a5; /* color of the scroll thumb */
  background-color: var(--color-primary); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid white; /* creates padding around scroll thumb */
}
@media print {
  header {
    display: none;
  }
  .ehc-day-shadow {
    box-shadow: none;
    page-break-inside: avoid;
    border: 1px solid #a7a5a5;
  }

  .scroll-snap-x {
    display: block;
  }
}
.tinymceview h1,
.admin h1 {
  font-size: 2.25rem !important;
}
.tinymceview h2,
.admin h2 {
  font-size: 2.25rem !important;
}
.tinymceview h3,
.admin h3 {
  font-size: 1.75rem !important;
}
.tinymceview h4,
.admin h4 {
  font-size: 1.25rem !important;
}

.tinymceview p {
  display: block;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.tinymceview li {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
